import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";
// import authHeader from "./jwt-token-access/auth-token-header"; "./jwt-token-access/auth-token-header";
import authHeader from "./jwt-token-access/auth-token-header";

//pass new generated access token here
const token = accessToken;

//apply base url for axios
const API_URL = "https://api.vsp.xpertie.com/api";
// const API_URL = "http://localhost:8080/api";

const axiosApi = axios.create({
  baseURL: API_URL,
});

// axiosApi.defaults.headers.common["Authorization"] = token;
axiosApi.defaults.headers = authHeader();

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function getAsync(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      return { status: true, response: response.data};
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      return { status: false, response: message};
    });
}

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
}


export async function postAsync(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      return { status: true, response: response.data};
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      return { status: false, response: message};
    });
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      return response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      return response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      return response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
}
