
const formatOption = (arr,label,val) => { 
  if (!arr || arr.length === 0) {
    return []; // Return an empty array or handle the case as needed
  }
  
  return arr.map((originalObject) => {
    return {
      label: originalObject[label],
      value: originalObject[val],
    };
  });
}

const parseMultiPickerValues = (arr,key) => { 
  if (!arr || arr.length === 0) {
    return []; // Return an empty array or handle the case as needed
  }

  let valuesArr = [];
  
  return arr.map((originalObject) => {
    return originalObject[key]
  });
}


const trimAndAddEllipsis = (inputString, maxLength) => {
  if (inputString.length > maxLength) {
    return inputString.substring(0, maxLength - 3) + '...';
  } else {
    return inputString;
  }
}
// export const getUserProfile = () => get(url.GET_USER_PROFILE)

const getCurrentUser = () => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    return obj
  } else {
    return null
  }
}

const calculateDiscount = (originalPrice,discountedPrice,addPercentIcon = false) => {
  const original = parseFloat(originalPrice);
  const discounted = parseFloat(discountedPrice);

  if (!isNaN(original) && !isNaN(discounted) && original > discounted) {
    const discount = ((original - discounted) / original) * 100;
    // setDiscountPercentage(discount.toFixed(2) + '%');
    if(addPercentIcon) {
      return discount.toFixed(2) + '%'
    } else {
      return discount.toFixed(2)
    }
  } else {
    return null;
    // setDiscountPercentage('Invalid input');
  }
};

export {
  formatOption,
  trimAndAddEllipsis,
  calculateDiscount,
  getCurrentUser,
  parseMultiPickerValues
}
