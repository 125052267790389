import React, { useEffect, useState } from "react"

import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import { post, del, get, put } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import Spinners from "components/Common/Spinner"
import { useDispatch } from "react-redux";
import makeAnimated from "react-select/animated";
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import * as Yup from "yup";
import { useFormik } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { formatOption, parseMultiPickerValues } from "helpers/functions";



const CourseAdd = (props) => {
  document.title = "Course Add - VSP";

  const animatedComponents = makeAnimated();
  const breadcrumbItems = [
    { title: "VSP", link: "#" },
    { title: "Course", link: "#" },
    { title: "Add", link: "#" },
  ]

  const [selectedMulti, setselectedMulti] = useState(null);

  const [qualifications, setQualifications] = useState([])
  const [selectedQualification, setSelectedQualification] = useState(null)
  
  const [courseTypes, setCourseTypes] = useState([])
  const [selectedCoursetype, setSelectedCourseType] = useState(null)

  const [institutes, setInsitutes] = useState([])
  const [selectedInstitute, setSelectedInstitute] = useState(null)

  const [allCourses, setAllCourses] = useState([])
  const [selectedParentCourses, setSelectedParentCourses] = useState([])
  const [selectedPrerequisitesCourses, setSelectedPrerequisitesCourses] = useState([])

  const [availabillity, setAvailabillity] = useState([])
  const [selectedAvailabillity, setSelectedAvailabillity] = useState(null)

  const [jobRoles, setJobRoles] = useState([])
  const [selectedJobRoles, setSelectedJobRoles] = useState([])

  const [skills, setSkills] = useState([])
  const [selectedSkills, setSelectedSkills] = useState([])

  const [languages, setLanguages] = useState([])
  const [selectedLanguages, setSelectedLanguages] = useState([])

  const [textareabadge, settextareabadge] = useState(0)
  const [textcount, settextcount] = useState(0)

  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)


  // const [selectedJobRoles, setSelectedJobRoles] = useState([])


  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: '',
      course_code: '',
      price: '',
      discount: '',
      // duration: '',
      course_link: '',
      start_date: '',
      total_duration: '',
      total_duration_unit: { label: "Days", value: "Days" },
      course_description: '',
      course_content: '',
      institute: null,
      course_type: null,
      qualification: [],
      prerequisites_courses: [],
      parent_courses: [],
      skills: [],
      languages: [],
      job_roles: [],
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      course_code: Yup.string().required("Please Enter Course Code"),
      price: Yup.number()
      .required('Price is required')
      .typeError('Invalid format')
      .min(0, 'Price must be a positive number')
      .test('decimal-format', 'Invalid decimal format', (value) => {
        if (value === undefined || value === null) return true; // Allow empty values
        return /^\d+(\.\d{1,2})?$/.test(value.toString());
      }),
      discount: Yup.number()
      .required('Discount is required')
      .typeError('Invalid format')
      .min(0, 'Discount must be at least 0')
      .max(100, 'Discount must be at most 100'),
      // duration: Yup.string().required("Please Enter Duration"),
      course_link: Yup.string().required("Please Enter Course Link"),
      total_duration: Yup.number()
      .required('Total Duration is required')
      .typeError('Invalid format')
      .min(0, 'Total Duration must be a positive number'),
      total_duration_unit: Yup.object().nullable().required("Please Enter Total Duration Unit"),
      course_description: Yup.string().required("Please Enter Course Description"),
      // course_content: Yup.object().required('Course Content is required'),
      institute: Yup.object().nullable().required("Please Select Institute"),
      course_type: Yup.object().nullable().required("Please Select Course Type"),
      qualification: Yup.array().min(1, "Please Select at least one Qualification"),
      prerequisites_courses: Yup.array().min(1, "Please Select at least one Prerequisites Course"),
      parent_courses: Yup.array().min(1, "Please Select at least one Parent Course"),
      skills: Yup.array().min(1, "Please Select at least one Skill"),
      // languages: Yup.array().min(1, "Please Select at least one Language"),
      job_roles: Yup.array().min(1, "Please Select at least one Job Role"),
      start_date: Yup.date().required('Start Date is required'),
    }),
    onSubmit: async (values) => {
      // Handle form submission logic here
      
      // let postData = { course_type: values.course_type.value, ...values, }

      let newBody = Object.assign({}, values);

      console.log("VALS 1",values);
      newBody.course_type = values.course_type.value
      newBody.institute = values.institute.value
      // newBody.job_roles = values.job_roles.value
      newBody.job_roles = parseMultiPickerValues(selectedJobRoles,"value")
      // newBody.languages = values.languages.value
      newBody.languages = parseMultiPickerValues(selectedLanguages,"value")
      // newBody.parent_courses = values.parent_courses.value
      newBody.parent_courses = parseMultiPickerValues(selectedParentCourses,"value")
      // newBody.prerequisites_courses = values.prerequisites_courses.value
      newBody.prerequisites_courses = parseMultiPickerValues(selectedPrerequisitesCourses,"value")
      // newBody.qualification = values.qualification.value
      newBody.qualification = parseMultiPickerValues(selectedQualification,"value")
      // newBody.skills = values.skills.value
      newBody.skills = parseMultiPickerValues(selectedSkills,"value")
      newBody.total_duration_unit = values.total_duration_unit.value

      console.log("VALS ",newBody);
      // let formPost = await post(url.CREATE_COURSE, values, { headers : {credentials: 'include', Accept : "*/*"}});
      // console.log("POSTED ",formPost)
    },
  });

  const total_duration_units = [
    { label: "Days", value: "Days" },
    { label: "Months", value: "Months" },
    { label: "Years", value: "Years" }
  ]

  // const optionGroup = [
  //   {
  //     label: "Picnic",
  //     options: [
  //       { label: "Mustard", value: "Mustard" },
  //       { label: "Ketchup", value: "Ketchup" },
  //       { label: "Relish", value: "Relish" }
  //     ]
  //   },
  //   {
  //     label: "Camping",
  //     options: [
  //       { label: "Tent", value: "Tent" },
  //       { label: "Flashlight", value: "Flashlight" },
  //       { label: "Toilet Paper", value: "Toilet Paper" }
  //     ]
  //   }
  // ];

  useEffect(() => {
    
    props.setBreadcrumbItems('Add', breadcrumbItems)
  })

  // function formatOption(arr,label,val) { 
  //     if (!arr || arr.length === 0) {
  //       return []; // Return an empty array or handle the case as needed
  //     }
      
  //     return arr.map((originalObject) => {
  //       return {
  //         label: originalObject[label],
  //         value: originalObject[val],
  //       };
  //     });
  // }

  useEffect(() => {
    async function fetchData() {
    
      let dropdowns = await get(url.GET_COURSE_DROPDOWNS, {}, { headers : {credentials: 'include', Accept : "*/*"}});

      // console.log(formatOption(dropdowns?.courseType,"name","course_type_id"))
      setCourseTypes(formatOption(dropdowns?.courseTypes,"name","course_type_id") || []);
      setAllCourses(formatOption(dropdowns?.courses,"title","courses_id") || []);
      setInsitutes(formatOption(dropdowns?.institutes,"name","institute_id") || []);
      setJobRoles(formatOption(dropdowns?.jobRoles,"name","job_role_id") || []);
      setQualifications(formatOption(dropdowns?.qualifications,"name","qualification_id") || []);
      setSkills(formatOption(dropdowns?.skills,"name","skill_id") || []);

      console.log("RESPONSE FETCHED",dropdowns)
      setLoading(false)
      
    }
    fetchData();

    // Perform your componentDidMount logic here
    console.log('Component has mounted');

  }, []); 



  

  function textareachange(event) {
    const count = event.target.value.length
    if (count > 0) {
      settextareabadge(true)
    } else {
      settextareabadge(false)
    }
    settextcount(event.target.value.length)
  }

  // const updateObjectByKey = (array, keyToUpdate, updatedObject) => {
  //   const updatedArray = array.map(obj => {
  //     if (obj[keyToUpdate] === updatedObject[keyToUpdate]) {
  //       return { ...obj, ...updatedObject };
  //     }
  //     return obj;
  //   });
  
  //   const keyExists = updatedArray.some(obj => obj[keyToUpdate] === updatedObject[keyToUpdate]);
  
  //   if (!keyExists) {
  //      updatedArray.push({ key : keyToUpdate, value: updatedObject});
  //   }
  
  //   return updatedArray;
  // };


  const handleSkillsSelect = (selectedOptions) => {
    validation.setFieldValue('skills', selectedOptions);
    setSelectedSkills(selectedOptions);
  };

  const handleLanguagesSelect = (selectedOptions) => {
    validation.setFieldValue('languages', selectedOptions);
    setSelectedLanguages(selectedOptions)
  };

  const handleJobRolesSelect = (selectedOptions) => {
    validation.setFieldValue('job_roles', selectedOptions);
    // const updatedArray = updateObjectByKey(dropdownValues, 'job_roles', selectedOptions);
    // console.log("V ",updatedArray )
    // setDropdownValues(updatedArray)
    setSelectedJobRoles(selectedOptions);
  };

  const handleInstituteSelect = (selectedOption) => {
    validation.setFieldValue('institute', selectedOption);
  };

  const handleCourseTypeSelect = (selectedOption) => {
    validation.setFieldValue('course_type', selectedOption);
  };

  const handleQualificationSelect = (selectedOptions) => {
    validation.setFieldValue('qualification', selectedOptions);
    // const updatedArray = updateObjectByKey(dropdownValues, 'qualification', selectedOptions);
    // setDropdownValues(updatedArray);
    setSelectedQualification(selectedOptions)
  };

  const handlePrerequisitesCourseSelect = (selectedOptions) => {
    validation.setFieldValue('prerequisites_courses', selectedOptions);
    setSelectedPrerequisitesCourses(selectedOptions)
  };

  const handleParentCourseSelect = (selectedOptions) => {
    validation.setFieldValue('parent_courses', selectedOptions);
    setSelectedParentCourses(selectedOptions)
  };

  const handleDurationUnitSelect = (selectedOptions) => {
    validation.setFieldValue('total_duration_unit', selectedOptions);
  };



  return (
    <React.Fragment>
    { isLoading ? <Spinners  /> :
      <Row>
        <Col xl="12">
          <Card>
          <Form
                className="form-horizontal mt-4"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
            <CardBody>
              <h4 className="card-title">Course Add</h4>
              <p className="card-title-desc"></p>
              
                
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Title</Label>
                      <Input
                        name="title"
                        placeholder="Title"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title || ''}
                        invalid={validation.touched.title && validation.errors.title ? true : false}
                      />
                      {validation.touched.title && validation.errors.title ? (
                        <div className="text-danger">{validation.errors.title}</div>
                      ) : null}
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom18">Course Code</Label>
                      <Input
                        name="course_code"
                        placeholder="Course Code"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.course_code || ''}
                        invalid={validation.touched.course_code && validation.errors.course_code ? true : false}
                      />
                      {validation.touched.course_code && validation.errors.course_code ? (
                        <div className="text-danger">{validation.errors.course_code}</div>
                      ) : null}
                    </div>
                  </Col>
                </Row>

  

                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Description</Label>
                      <Input
                        type="textarea"
                        name="course_description"
                        id="course_description"
                        rows="5"
                        placeholder="Enter course description"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.course_description || ''}
                        invalid={
                          validation.touched.course_description && validation.errors.course_description
                            ? true
                            : false
                        }
                      />
                      {validation.touched.course_description && validation.errors.course_description ? 
                          <div className="text-danger">{validation.errors.course_description}</div> 
                      : null}
                    </div>
                  </Col>
                </Row>

                
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="institute">Institute</Label>
                      <Select
                        value={validation.values.institute}
                        isMulti={false}
                        onChange={handleInstituteSelect}
                        options={institutes}
                        id="institute"
                        classNamePrefix="select2-selection"
                        isValid={!validation.errors.institute && validation.touched.institute}
                      />
                      {validation.touched.institute && validation.errors.institute ? (
                        <div className="text-danger">{validation.errors.institute}</div>
                      ) : null}
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="course_type">Course Type</Label>
                      <Select
                        value={validation.values.course_type}
                        isMulti={false}
                        onChange={handleCourseTypeSelect}
                        options={courseTypes}
                        id="course_type"
                        classNamePrefix="select2-selection"
                        isValid={!validation.errors.course_type && validation.touched.course_type}
                      />
                      {validation.touched.course_type && validation.errors.course_type ? (
                        <div className="text-danger">{validation.errors.course_type}</div>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="qualifications">Required Qualifications</Label>
                      <Select
                        value={validation.values.qualification}
                        isMulti={true}
                        onChange={handleQualificationSelect}
                        options={qualifications}
                        id="qualifications"
                        classNamePrefix="select2-selection"
                        isValid={!validation.errors.qualification && validation.touched.qualification}
                      />
                      {validation.touched.qualification && validation.errors.qualification ? (
                        <div className="text-danger">{validation.errors.qualification}</div>
                      ) : null}
                    </div>
                  </Col>

                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="prerequisites_courses">Prerequisites Courses</Label>
                      <Select
                        value={validation.values.prerequisites_courses}
                        isMulti={true}
                        onChange={handlePrerequisitesCourseSelect}
                        options={allCourses}
                        id="prerequisites_courses"
                        classNamePrefix="select2-selection"
                        isValid={!validation.errors.prerequisites_courses && validation.touched.prerequisites_courses}
                      />
                      {validation.touched.prerequisites_courses && validation.errors.prerequisites_courses ? (
                        <div className="text-danger">{validation.errors.prerequisites_courses}</div>
                      ) : null}
                    </div>
                  </Col>

                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="parent_courses">Parent Courses</Label>
                      <Select
                        value={validation.values.parent_courses}
                        isMulti={true}
                        onChange={handleParentCourseSelect}
                        options={allCourses}
                        id="parent_courses"
                        classNamePrefix="select2-selection"
                        isValid={!validation.errors.parent_courses && validation.touched.parent_courses}
                      />
                     {validation.touched.parent_courses && validation.errors.parent_courses ? (
                        <div className="text-danger">{validation.errors.parent_courses}</div>
                      ) : null}
                    </div>
                  </Col>
                </Row>

        
                <Row>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom04">Price</Label>
                      <Input
                        name="price"
                        placeholder="Price"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.price || ''}
                        invalid={validation.touched.price && validation.errors.price ? true : false}
                      />
                      {validation.touched.price && validation.errors.price ? (
                        <div className="text-danger">{validation.errors.price}</div>
                      ) : null}
                    </div>
                  </Col>

                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">Discount</Label>
                      <Input
                        name="discount"
                        placeholder="Discount"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.discount || ''}
                        invalid={validation.touched.discount && validation.errors.discount ? true : false}
                      />
                      {validation.touched.discount && validation.errors.discount ? (
                        <div className="text-danger">{validation.errors.discount}</div>
                      ) : null}
                    </div>
                  </Col>

                  {/* <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom08">Duration</Label>
                      <Input
                        name="duration"
                        placeholder="Duration"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.duration || ''}
                        invalid={validation.touched.duration && validation.errors.duration ? true : false}
                      />
                      {validation.touched.duration && validation.errors.duration ? (
                        <div className="text-danger">{validation.errors.duration}</div>
                      ) : null}
                    </div>
                  </Col> */}

                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom06">Course Link</Label>
                      <Input
                        name="course_link"
                        placeholder="Course Link"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.course_link || ''}
                        invalid={validation.touched.course_link && validation.errors.course_link ? true : false}
                      />
                      {validation.touched.course_link && validation.errors.course_link ? (
                        <div className="text-danger">{validation.errors.course_link}</div>
                      ) : null}
                    </div>
                  </Col>
                </Row>

               
                <Row>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="skills">Required Skills</Label>
                      <Select
                        value={validation.values.skills}
                        isMulti={true}
                        onChange={handleSkillsSelect}
                        name="skills"
                        options={skills}
                        id="skills"
                        classNamePrefix="select2-selection"
                        isValid={!validation.errors.skills && validation.touched.skills}
                      />
                      {validation.touched.skills && validation.errors.skills ? (
                        <div className="text-danger">{validation.errors.skills}</div>
                      ) : null}
                    </div>
                  </Col>

                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="languages">Course Languages</Label>
                      <Select
                        value={validation.values.languages}
                        isMulti={true}
                        onChange={handleLanguagesSelect}
                        name="languages"
                        options={languages}
                        id="languages"
                        classNamePrefix="select2-selection"
                        isValid={!validation.errors.languages && validation.touched.languages}
                      />
                      {validation.touched.languages && validation.errors.languages ? (
                        <div className="text-danger">{validation.errors.languages}</div>
                      ) : null}
                    </div>
                  </Col>

                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="job_roles">Job Roles</Label>
                      <Select
                        value={validation.values.job_roles}
                        isMulti={true}
                        onChange={handleJobRolesSelect}
                        name="job_roles"
                        options={jobRoles}
                        id="job_roles"
                        classNamePrefix="select2-selection"
                        isValid={!validation.errors.job_roles && validation.touched.job_roles}
                      />
                      {validation.touched.job_roles && validation.errors.job_roles ? (
                        <div className="text-danger">{validation.errors.job_roles}</div>
                      ) : null}
                    </div>
                  </Col>
                </Row>

      
                <Row>
                  <Col md="12">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom13">Course Content</Label>
                      <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName={`editorClassName ${validation.touched.course_content && validation.errors.course_content ? 'is-invalid' : ''}`}
                        onEditorStateChange={(editorState) => validation.setFieldValue('course_content', editorState)}
                      />
                      {validation.touched.course_content && validation.errors.course_content ? (
                        <div className="text-danger">{validation.errors.course_content}</div>
                      ) : null}
                    </div>
                  </Col>
                </Row>

               
                <Row>
                  
                  <Col md="4">
                    {/* <div className="mb-3">
                      <Label htmlFor="validationCustom20">Start Date</Label>
                      <Input
                        name="start_date"
                        placeholder="Start Date"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.start_date || ''}
                        invalid={validation.touched.start_date && validation.errors.start_date ? true : false}
                      />
                      {validation.touched.start_date && validation.errors.start_date ? (
                        <div className="text-danger">{validation.errors.start_date}</div>
                      ) : null}
                    </div> */}
                    <div className="form-group mb-4">
                      <Label>Start Date</Label>
                      <InputGroup>
                        <Flatpickr
                          name="start_date"
                          className={`form-control d-block ${validation.touched.start_date && validation.errors.start_date ? 'is-invalid' : ''}`}
                          placeholder="MM/DD/YYYY"
                          value={validation.values.start_date}
                          options={{
                            altInput: true,
                            altFormat: "m/d/Y",
                            dateFormat: "Y-m-d"
                          }}
                          onChange={(selectedDates) => {
                            validation.setFieldValue('start_date', selectedDates[0]);
                          }}
                        />
                      </InputGroup>
                      {validation.touched.start_date && validation.errors.start_date ? (
                        <div className="text-danger">{validation.errors.start_date}</div>
                      ) : null}
                    </div>
                  </Col>

                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom21">Total Duration</Label>
                      <Input
                        name="total_duration"
                        placeholder="Total Duration"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.total_duration || ''}
                        invalid={
                          validation.touched.total_duration && validation.errors.total_duration ? true : false
                        }
                      />
                      {validation.touched.total_duration && validation.errors.total_duration ? (
                        <div className="text-danger">{validation.errors.total_duration}</div>
                      ) : null}
                    </div>
                  </Col>

                  {/* <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom22">Total Duration Unit</Label>
                      <Input
                        name="total_duration_unit"
                        placeholder="Total Duration Unit"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.total_duration_unit || ''}
                        invalid={
                          validation.touched.total_duration_unit && validation.errors.total_duration_unit
                            ? true
                            : false
                        }
                      />
                      {validation.touched.total_duration_unit && validation.errors.total_duration_unit ? (
                        <div className="text-danger">{validation.errors.total_duration_unit}</div>
                      ) : null}
                    </div>
                  </Col> */}

                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="total_duration_unit">Total Duration Unit</Label>
                      <Select
                        value={validation.values.total_duration_unit}
                        isMulti={false}
                        onChange={handleDurationUnitSelect}
                        name="total_duration_unit"
                        options={total_duration_units}
                        id="total_duration_unit"
                        classNamePrefix="select2-selection"
                        isValid={!validation.errors.total_duration_unit && validation.touched.total_duration_unit}
                      />
                      {validation.touched.total_duration_unit && validation.errors.total_duration_unit ? (
                        <div className="text-danger">{validation.errors.total_duration_unit}</div>
                      ) : null}
                    </div>
                  </Col>

                </Row>

                

                {/* <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom25">Child Courses</Label>
                      <AvField
                        name="child_courses"
                        placeholder="Child Courses"
                        type="text"
                        errorMessage="Enter Child Courses"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom25"
                      />
                    </div>
                  </Col>
                </Row> */}

                {/* <div className="form-check mb-3">
                  <input className="form-check-input" type="checkbox" value="" id="invalidCheck"
                    required />
                  <label className="form-check-label" htmlFor="invalidCheck">
                    Agree to terms and conditions
                                        </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                                        </div>
                </div> */}
                {/* <Button color="primary" type="submit">
                  Add
                    </Button> */}
              
            </CardBody>

            <CardFooter>

                <Row>
                  <Col md="6">
                      <Button color="primary" type="submit">
                          Add
                      </Button>
                  </Col>
                </Row>
            </CardFooter>
            </Form>
          </Card>
        </Col>
        {/* <Col xl="12">
          <Card>
            <CardBody>
              <h4 className="card-title">Course Add</h4>
              <p className="card-title-desc"></p>
              <AvForm method="post"
                onSubmit={e => {
                  handleSubmit(e)
                }}
                className="needs-validation">
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">First name</Label>
                      <AvField
                        name="firstname"
                        placeholder="First name"
                        type="text"
                        errorMessage="Enter First Name"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Last name</Label>
                      <AvField
                        name="lastname"
                        placeholder="Last name"
                        type="text"
                        errorMessage="Enter Last name"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom02"
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                        <Label htmlFor="validationCustom02">Multi Select</Label>
                        <Select
                          value={selectedMulti}
                          isMulti={true}
                          onChange={() => {
                            handleMulti();
                          }}
                          options={optionGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom03">City</Label>
                      <AvField
                        name="city"
                        placeholder="City"
                        type="text"
                        errorMessage=" Please provide a valid city."
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom03"
                      />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom04">State</Label>
                      <AvField
                        name="state"
                        placeholder="State"
                        type="text"
                        errorMessage="Please provide a valid state."
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom04"
                      />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">Zip</Label>
                      <AvField
                        name="zip"
                        placeholder="Zip Code"
                        type="text"
                        errorMessage=" Please provide a valid zip."
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom05"
                      />
                    </div>
                  </Col>
                </Row>
                <div className="form-check mb-3">
                  <input className="form-check-input" type="checkbox" value="" id="invalidCheck"
                    required />
                  <label className="form-check-label" htmlFor="invalidCheck">
                    Agree to terms and conditions
                                        </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                                        </div>
                </div>
                <Button color="primary" type="submit">
                  Submit form
                    </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col> */}

      
      </Row>}
    

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(CourseAdd);