import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Pages Calendar
import Calendar from "../pages/Calendar/index"

//Email
import EmailInbox from "../pages/Email/email-inbox"
import EmailRead from "../pages/Email/email-read"
import EmailCompose from "../pages/Email/email-compose"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Charts
import ChartsAppex from "../pages/Charts/charts-appex";
import ChartsJs from "../pages/Charts/charts-chartjs";
import ChartsKnob from "../pages/Charts/charts-knob";
import ChartsC3 from "../pages/Charts/charts-c3";
import ChartsSparkLine from "../pages/Charts/charts-sparkline";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle"
import MapsVector from "../pages/Maps/MapsVector"

//Icons
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
import Iconion from "../pages/Icons/Iconion"
import IconFontawesome from "../pages/Icons/IconFontawesome"
import IconThemify from "../pages/Icons/IconThemify"
import IconDripicons from "../pages/Icons/IconDripicons"
import IconTypicons from "../pages/Icons/IconTypicons"

//Tables
import BasicTables from "../pages/Tables/BasicTables"
import DatatableTables from "../pages/Tables/DatatableTables"
import ResponsiveTables from "../pages/Tables/ResponsiveTables"
import EditableTables from "../pages/Tables/EditableTables"

// Forms
import FormElements from "../pages/Forms/FormElements"
import FormAdvanced from "../pages/Forms/FormAdvanced"
import FormEditors from "../pages/Forms/FormEditors"
import FormValidations from "../pages/Forms/FormValidations"
import FormUpload from "../pages/Forms/FormUpload"
import FormXeditable from "../pages/Forms/FormXeditable"

//Ui
import UiAlert from "../pages/Ui/UiAlert"
import UiButtons from "../pages/Ui/UiButtons"
import UiBadge from "../pages/Ui/UiBadge"
import UiCards from "../pages/Ui/UiCards"
import UiCarousel from "../pages/Ui/UiCarousel"
import UiDropdown from "../pages/Ui/UiDropdown"
import UiGrid from "../pages/Ui/UiGrid"
import UiImages from "../pages/Ui/UiImages"
import UiLightbox from "../pages/Ui/UiLightbox"
import UiModal from "../pages/Ui/UiModal"
import UiPagination from "../pages/Ui/UiPagination"
import UiPopoverTooltips from "../pages/Ui/UiPopoverTooltips"
import UiProgressbar from "../pages/Ui/UiProgressbar"
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
import UiTypography from "../pages/Ui/UiTypography"
import UiVideo from "../pages/Ui/UiVideo"
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
import UiRangeSlider from "../pages/Ui/UiRangeSlider"

//Extra Pages
import PagesTimeline from "../pages/Extra Pages/pages-timeline";
import PagesInvoice from "../pages/Extra Pages/pages-invoice";
import PagesDirectory from "../pages/Extra Pages/pages-directory";
import PagesBlank from "../pages/Extra Pages/pages-blank";
import Pages404 from "../pages/Extra Pages/pages-404";
import Pages500 from "../pages/Extra Pages/pages-500";
import UiUtilities from "pages/Ui/UiUtilities"
import UiColors from "pages/Ui/UiColors"
import UiOffcanvas from "pages/Ui/UiOffcanvas"
import Chat from "pages/Chat/Chat";
import Kanban from "pages/Kanban"
import CourseAdd from "pages/Courses/CourseAdd"
import LanguageAdd from "pages/Languages/LanguageAdd"
import InstituteAdd from "pages/Insitute/InstituteAdd"
import QualificationAdd from "pages/Qualifications/QualificationAdd"
import JobRoleAdd from "pages/JobRoles/JobRoleAdd"
import SkillAdd from "pages/Skills/SkillAdd"
import SkillView from "pages/Skills/SkillsView"
import QualificationsView from "pages/Qualifications/QualificationsView"
import CourseView from "pages/Courses/CourseView"
import LanguageView from "pages/Languages/LanguageView"
import InstituteView from "pages/Insitute/InstituteView"
import JobRoleView from "pages/JobRoles/JobRoleView"

// import AvailabilityAdd from "pages/Master/AvailabilityAdd"
import AvailabilityView from "pages/Master/AvailabilityView"
// import BadgeAdd from "pages/Master/BadgeAdd"
// import BadgeView from "pages/Master/BadgeView"
// import CourseTypeAdd from "pages/Master/CourseTypeAdd"
// import CourseTypeView from "pages/Master/CourseTypeView"
// import InstituteCategoryAdd from "pages/Master/InstituteCategoryAdd"
// import InstituteCategoryView from "pages/Master/InstituteCategoryView"
// import JobRoleCategoryAdd from "pages/Master/JobRoleCategoryAdd"
// import JobRoleCategoryView from "pages/Master/JobRoleCategoryView"

import MyanswersQuestionAdd from "pages/Master/MyanswersQuestionAdd"
import MyanswersQuestionView from "pages/Master/MyanswersQuestionView"
import MyskillsQuestionAdd from "pages/Master/MyskillsQuestionAdd"
import MyskillsQuestionView from "pages/Master/MyskillsQuestionView"
// import SkillsCategoryAdd from "pages/Master/SkillsCategoryAdd"
// import SkillsCategoryView from "pages/Master/SkillsCategoryView"
// import WorkloadTypeAdd from "pages/Master/WorkloadTypeAdd"
// import WorkloadTypeView from "pages/Master/WorkloadTypeView"
import AvailabilitiesView from "pages/Availability/AvailabilitiesView"
import AvailabilityAdd from "pages/Availability/AvailabilityAdd"
import CourseTypeAdd from "pages/CourseType/CourseTypeAdd"
import CourseTypeView from "pages/CourseType/CourseTypeView"
import InstituteCategoryAdd from "pages/Insitute/InstituteCategoryAdd"
import InstituteCategoryView from "pages/Insitute/InstituteCategoryView"
import SkillsCategoryAdd from "pages/Skills/SkillsCategoryAdd"
import SkillsCategoryView from "pages/Skills/SkillsCategoryView"
import JobRoleCategoryAdd from "pages/JobRoles/JobRoleCategoryAdd"
import JobRoleCategoryView from "pages/JobRoles/JobRoleCategoryView"
import QualificationCategoryAdd from "pages/Qualifications/QualificationCategoryAdd"
import QualificationCategoryView from "pages/Qualifications/QualificationCategoryView"
import WorkLoadTypeAdd from "pages/WorkLoadType/WorkLoadTypeAdd"
import WorkLoadTypeView from "pages/WorkLoadType/WorkLoadTypeView"
import BadgeAdd from "pages/Badge/BadgeAdd"
import BadgeView from "pages/Badge/BadgeView"


const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  
  { path: "/course-add", component: <CourseAdd /> },
  { path: "/course-view", component: <CourseView /> },
  
  { path: "/language-add", component: <LanguageAdd /> },
  { path: "/language-view", component: <LanguageView /> },
  
  { path: "/institute-add", component: <InstituteAdd /> },
  { path: "/institute-edit/:id", component: <InstituteAdd /> },
  { path: "/institute-view", component: <InstituteView /> },
  { path: "/institute-category-add", component: <InstituteCategoryAdd /> },
  { path: "/institute-category-edit/:id", component: <InstituteCategoryAdd /> },
  { path: "/institute-category-view", component: <InstituteCategoryView /> },
  
  { path: "/qualification-add", component: <QualificationAdd /> },
  { path: "/qualification-edit/:id", component: <QualificationAdd /> },
  { path: "/qualification-view", component: <QualificationsView /> },
  { path: "/qualification-category-add", component: <QualificationCategoryAdd /> },
  { path: "/qualification-category-edit/:id", component: <QualificationCategoryAdd /> },
  { path: "/qualification-category-view", component: <QualificationCategoryView /> },
  
  { path: "/job-role-add", component: <JobRoleAdd /> },
  { path: "/job-role-edit/:id", component: <JobRoleAdd /> },
  { path: "/job-role-view", component: <JobRoleView /> },
  { path: "/job-role-category-add", component: <JobRoleCategoryAdd /> },
  { path: "/job-role-category-edit/:id", component: <JobRoleCategoryAdd /> },
  { path: "/job-role-category-view", component: <JobRoleCategoryView /> },
  
  { path: "/skill-add", component: <SkillAdd /> },
  { path: "/skill-edit/:id", component: <SkillAdd /> },
  { path: "/skill-view", component: <SkillView /> },
  { path: "/skill-category-add", component: <SkillsCategoryAdd /> },
  { path: "/skill-category-edit/:id", component: <SkillsCategoryAdd /> },
  { path: "/skill-category-view", component: <SkillsCategoryView /> },

  { path: "/availability-add", component: <AvailabilityAdd /> },
  { path: "/availability-edit/:id", component: <AvailabilityAdd /> },
  { path: "/availability-view", component: <AvailabilitiesView /> },

  { path: "/course-type-add", component: <CourseTypeAdd /> },
  { path: "/course-type-edit/:id", component: <CourseTypeAdd /> },
  { path: "/course-type-view", component: <CourseTypeView /> },

  { path: "/work-load-type-add", component: <WorkLoadTypeAdd /> },
  { path: "/work-load-type-edit/:id", component: <WorkLoadTypeAdd /> },
  { path: "/work-load-type-view", component: <WorkLoadTypeView /> },

  { path: "/badge-add", component: <BadgeAdd /> },
  { path: "/badge-edit/:id", component: <BadgeAdd /> },
  { path: "/badge-view", component: <BadgeView /> },


  //Master
  // { path: "/availability-add", component: <AvailabilityAdd /> },
  // { path: "/availability-view", component: <AvailabilityView /> },

  // { path: "/badge-add", component: <BadgeAdd /> },
  // { path: "/badge-view", component: <BadgeView /> },

  // { path: "/coursetype-add", component: <CourseTypeAdd /> },
  // { path: "/coursetype-view", component: <CourseTypeView /> },

  // { path: "/institute-category-add", component: <InstituteCategoryAdd /> },
  // { path: "/institute-category-view", component: <InstituteCategoryView /> },

  // { path: "/job-role-category-add", component: <JobRoleCategoryAdd /> },
  // { path: "/job-role-category-view", component: <JobRoleCategoryView /> },

  { path: "/myanswers-questions-add", component: <MyanswersQuestionAdd /> },
  { path: "/myanswers-questions-view", component: <MyanswersQuestionView /> },

  { path: "/myskills-questions-add", component: <MyskillsQuestionAdd /> },
  { path: "/myskills-questions-view", component: <MyskillsQuestionView /> },

  // { path: "/skills-category-add", component: <SkillsCategoryAdd /> },
  // { path: "/skills-category-view", component: <SkillsCategoryView /> },

  // { path: "/workload-type-add", component: <WorkloadTypeAdd /> },
  // { path: "/workload-type-view", component: <WorkloadTypeView /> },

  // //calendar
  { path: "/calendar", component: <Calendar /> },

  { path: "/chat", component: <Chat /> },
  { path: "/kanbanboard", component: <Kanban /> },

  // // //profile
  { path: "/profile", component: <UserProfile /> },

  // //Email
  { path: "/email-inbox", component: <EmailInbox /> },
  { path: "/email-read", component: <EmailRead /> },
  { path: "/email-compose", component: <EmailCompose /> },

  // //Charts
  { path: "/apex-charts", component: <ChartsAppex /> },
  { path: "/charts-chartjs", component: <ChartsJs /> },
  { path: "/charts-knob", component: <ChartsKnob /> },
  { path: "/charts-c3", component: <ChartsC3 /> },
  { path: "/sparkline-charts", component: <ChartsSparkLine /> },

  // // Icons
  { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
  { path: "/icons-ion", component: <Iconion /> },
  { path: "/icons-fontawesome", component: <IconFontawesome /> },
  { path: "/icons-themify", component: <IconThemify /> },
  { path: "/icons-dripicons", component: <IconDripicons /> },
  { path: "/icons-typicons", component: <IconTypicons /> }, 
  
  // // Tables
  { path: "/tables-basic", component: <BasicTables /> },
  { path: "/tables-datatable", component: <DatatableTables /> },
  { path: "/tables-responsive", component: <ResponsiveTables /> },
  { path: "/tables-editable", component: <EditableTables /> },

  // // Maps
  { path: "/maps-google", component: <MapsGoogle /> },
  { path: "/maps-vector", component: <MapsVector /> },

  // // Forms
  { path: "/form-elements", component: <FormElements /> },
  { path: "/form-advanced", component: <FormAdvanced /> },
  { path: "/form-editors", component: <FormEditors /> },
  { path: "/form-uploads", component: <FormUpload /> },
  { path: "/form-validation", component: <FormValidations /> },
  { path: "/form-xeditable", component: <FormXeditable /> },

  // // Ui
  { path: "/ui-alerts", component: <UiAlert /> },
  { path: "/ui-buttons", component: <UiButtons /> },
  { path: "/ui-badge", component: <UiBadge /> },
  { path: "/ui-cards", component: <UiCards /> },
  { path: "/ui-carousel", component: <UiCarousel /> },
  { path: "/ui-dropdowns", component: <UiDropdown /> },
  { path: "/ui-grid", component: <UiGrid /> },
  { path: "/ui-images", component: <UiImages /> },
  { path: "/ui-lightbox", component: <UiLightbox /> },
  { path: "/ui-modals", component: <UiModal /> },
  { path: "/ui-pagination", component: <UiPagination /> },
  { path: "/ui-popover-tooltip", component: <UiPopoverTooltips /> },
  { path: "/ui-progressbars", component: <UiProgressbar /> },
  { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
  { path: "/ui-typography", component: <UiTypography /> },
  { path: "/ui-video", component: <UiVideo /> },
  { path: "/ui-session-timeout", component: <UiSessionTimeout /> },
  { path: "/ui-rangeslider", component: <UiRangeSlider /> },
  { path: "/ui-utilities", component: <UiUtilities /> },
  { path: "/ui-colors", component: <UiColors /> },
  { path: "/ui-offcanvas", component: <UiOffcanvas /> },

  // //Extra Pages
  { path: "/pages-timeline", component: <PagesTimeline /> },
  { path: "/pages-invoice", component: <PagesInvoice /> },
  { path: "/pages-directory", component: <PagesDirectory /> },
  { path: "/pages-blank", component: <PagesBlank /> },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
]

export { userRoutes, authRoutes }